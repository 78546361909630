{
    "name": "sharemac-project-planner-angular",
    "version": "5.8.0",
    "scripts": {
        "ng": "ng",
        "start": "ng serve",
        "serve": "ng serve --host=0.0.0.0",
        "serve:docker": "ng serve --host=pp.sharemac.localhost",
        "start:dev": "ng serve --configuration=development",
        "start:syniotec": "ng serve --configuration=syniotec",
        "start:strabag": "ng serve --configuration=strabag",
        "start:strabag-prod": "ng serve --configuration=strabag-prod",
        "build:prod": "ng build --configuration=production",
        "build:prod:sentry": "ng build --configuration=production && npm run sentry:sourcemaps",
        "build:dev": "ng build --configuration=development",
        "build:stats": "ng build --stats-json",
        "test": "jest",
        "test:watch": "jest --watch --coverage=false",
        "test:coverage": "jest --coverage",
        "lint": "eslint . --ext .ts",
        "lint:fix": "eslint . --fix",
        "e2e": "ng e2e --dev-server-target=",
        "postinstall": "patch-package",
        "analyzer": "webpack-bundle-analyzer dist/sam/browser/stats.json",
        "prepare": "husky install",
        "generate:palette": "gulp generate:palette",
        "sentry:sourcemaps": "sentry-cli sourcemaps inject --org syniotec-techhub --project sam-frontend ./dist/sam/browser && sentry-cli sourcemaps upload --org syniotec-techhub --project sam-frontend ./dist/sam/browser"
    },
    "extensionDependencies": [
        "vunguyentuan.vscode-css-variables"
    ],
    "private": true,
    "dependencies": {
        "@angular-architects/native-federation": "^17.1.8",
        "@angular/animations": "17.3.1",
        "@angular/cdk": "17.3.1",
        "@angular/common": "17.3.1",
        "@angular/compiler": "17.3.1",
        "@angular/core": "17.3.1",
        "@angular/forms": "17.3.1",
        "@angular/google-maps": "17.3.1",
        "@angular/localize": "17.3.1",
        "@angular/material": "17.3.1",
        "@angular/platform-browser": "17.3.1",
        "@angular/platform-browser-dynamic": "17.3.1",
        "@angular/router": "17.3.1",
        "@apollo/client": "^3.11.8",
        "@fortawesome/fontawesome-free": "^5.14.0",
        "@iharbeck/ngx-virtual-scroller": "^17.0.2",
        "@mobiscroll/angular": "npm:@mobiscroll/angular-ivy@5.31.0",
        "@ng-select/ng-select": "^12.0.7",
        "@ngneat/svg-icon": "7.1.0",
        "@ngneat/until-destroy": "^9.2.2",
        "@ngrx/component-store": "17.1.1",
        "@ngrx/effects": "17.1.1",
        "@ngrx/entity": "17.1.1",
        "@ngrx/router-store": "17.1.1",
        "@ngrx/signals": "^17.1.1",
        "@ngrx/store": "17.1.1",
        "@ngx-translate/core": "15.0.0",
        "@ngx-translate/http-loader": "^7.0.0",
        "@sentry/angular": "^8.2.1",
        "@sentry/cli": "^2.38.2",
        "@sharemactechhub/frontend-library": "17.3.13",
        "@swimlane/ngx-charts": "^20.3.0",
        "angular-notifier": "^14.0.0",
        "angular-svg-icon": "^17.0.0",
        "angularx-qrcode": "17.0.1",
        "apollo-angular": "7.0.1",
        "chart.js": "^3.7.1",
        "chartjs-plugin-datalabels": "^2.0.0",
        "date-fns": "^2.28.0",
        "date-holidays": "^3.23.12",
        "es-module-shims": "^1.9.0",
        "event-source-polyfill": "^1.0.8",
        "file-saver": "^2.0.5",
        "graphql": "^16.6.0",
        "jwt-decode": "^3.0.0",
        "lodash-es": "^4.17.11",
        "lottie-web": "^5.12.2",
        "moment": "^2.24.0",
        "moment-timezone": "^0.5.27",
        "ng-mocks": "^14.12.2",
        "ng-otp-input": "^1.8.5",
        "ng2-charts": "^3.0.11",
        "ng2-pdfjs-viewer": "^17.0.3",
        "ngrx-store-localstorage": "^17.0.0",
        "ngx-cookie-service": "^17.0.0",
        "ngx-image-cropper": "^6.3.4",
        "ngx-infinite-scroll": "17.0.0",
        "ngx-lottie": "^10.0.0",
        "ngx-mask": "13.0.1",
        "ngx-permissions": "^17.0.0",
        "patch-package": "^7.0.1",
        "rxjs": "~7.8.0",
        "socket.io-client": "^4.1.3",
        "subscriptions-transport-ws": "^0.11.0",
        "tslib": "^2.6.2",
        "underscore": "^1.9.2",
        "uuid": "^8.3.2",
        "zone.js": "^0.14.4"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "17.3.1",
        "@angular-eslint/builder": "17.4.1",
        "@angular-eslint/eslint-plugin": "17.4.1",
        "@angular-eslint/eslint-plugin-template": "17.4.1",
        "@angular-eslint/schematics": "17.4.1",
        "@angular-eslint/template-parser": "17.4.1",
        "@angular/cli": "17.3.1",
        "@angular/compiler-cli": "17.3.1",
        "@angular/language-service": "17.3.1",
        "@commitlint/cli": "^17.1.2",
        "@ngrx/store-devtools": "^17.2.0",
        "@types/chart.js": "^2.9.24",
        "@types/file-saver": "^2.0.0",
        "@types/google.maps": "^3.53.1",
        "@types/gulp": "^4.0.10",
        "@types/hammerjs": "^2.0.36",
        "@types/jest": "^29.5.12",
        "@types/jquery": "^3.3.25",
        "@types/moment-timezone": "^0.5.12",
        "@types/mousetrap": "^1.6.3",
        "@types/node": "^20.2.3",
        "@types/qs": "^6.5.3",
        "@types/underscore": "^1.9.4",
        "@types/url-parse": "^1.4.3",
        "@typescript-eslint/eslint-plugin": "^7.2.0",
        "@typescript-eslint/parser": "^7.2.0",
        "codelyzer": "^6.0.2",
        "eslint": "^8.41.0",
        "eslint-plugin-promise": "^6.1.1",
        "eslint-plugin-unused-imports": "^2.0.0",
        "gulp": "^5.0.0",
        "gulp-sass": "^5.1.0",
        "husky": "^8.0.0",
        "jest": "^29.7.0",
        "jest-canvas-mock": "^2.5.2",
        "jest-junit": "^16.0.0",
        "jest-marbles": "^3.1.0",
        "jest-preset-angular": "^14.0.2",
        "prettier": "^2.7.1",
        "pretty-quick": "^3.1.3",
        "sass": "^1.62.0",
        "ts-jest": "^29.1.2",
        "ts-node": "~10.9.1",
        "typescript": "^5.4.5",
        "webpack-bundle-analyzer": "^4.5.0"
    },
    "husky": {
        "hooks": {
            "commit-msg": "commitlint -E HUSKY_GIT_PARAMS"
        }
    }
}
